
.main-wrapper{
    padding: 0 20px;
    .top-content{
        position: relative;
        .btn-back{
            position: absolute;
            // top: -12px;
            right: 20px;
        }
    }
    .answer{
        .answer-title{
            padding:20px 0;
            margin: 0;
            font-size:24px;
        }
    }
    .content{
        border: 1px solid #EBEEF5;
        border-bottom: none;
        height: 100%;
        .item{
            padding:20px 20px;
            border-bottom: 1px solid #EBEEF5;
            .span-title{
                display: inline-block;
                text-align: right;
                width:70px;
            }
        }
    }
    .freight-detail{
        .freight-title {
            font-size: 20px;
            padding-left: 15px;
            line-height: 1;
            position: relative;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                transform: translateY(-50%);
            }
        }
        .freight-detail-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .btn-back{
                margin-top: -5px;
            }
        }
    }
    ::v-deep .el-table__body-wrapper{
        max-height: 630px;
        overflow-y: auto;
    }
}
